export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz zmiany"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisywanie..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj ID"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dzisiaj o ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wczoraj o ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie masz uprawinień do pisania na tym kanale."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepołączony"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj wiadomość"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napisz wiadomość"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót na dół"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe wiadomości"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompresuj"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij na"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz profil"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj role"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrzuć"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbanuj"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopuj Użytkownik:Tag"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cytat"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edyduj"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako przeczytane"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia serwera"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opuść serwer"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wycisz kanał"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odcisz kanał"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie emoji"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje uruchamiania"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność programu"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie botami"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfejs"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń konto"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziennik zmian"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcje WIP"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj profil"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień hasło"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz dysk google ponownie"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz dysk google"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej profilu"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta informacja jest widoczna dla każdego."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień ustawienia powiadomień."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzwięk powiadomień"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydaje dzwięk, żeby zdobyć twoje zainteresowanie"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienie push"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymaj pomiadomienie push, nawet kiedy aplikacja jest zamknięta."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij swoje emoji za darmo! Emotki muszą ważyć 3MB albo mniej."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsługiwane typy plików: png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj emoji"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje uruchamiania aplikacji"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz aplikację, żeby modyfikować te ustawienia."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwórz przy uruchamianiu systemu"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziel się programami lub grami, z których korzystasz poprzez dodanie ich niżej. Podmieni to twój status własny, kiedy program jest uruchomiony i przywróci go, kiedy program jest zamknięty."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz aplikację, żeby używać funkcji aktywności programu."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twórz użytkowników botów, które pozwolą ci używac naszego API do tworzenia fantastycznych botów."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj komendy bota, żeby powiedzieć użytkownikom jakie ma komendy"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stwórz bota"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj komendę"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj bota"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj komendy"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stwórz link zaproszenia"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj link zaproszenia"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj tokenem"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż token"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj token"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj token"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń bota"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dążysz do usunięcia swojego konta"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co zostanie usunięte"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co nie zostanie usunięte"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje wiadomości wysłane na serwerach"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagania"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opuść/Usuń wszystkie serwery"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wszystkie boty"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń moje konto"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload may be required after changing language. Contribute by submitting a PR in the GitHub repo."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translators"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień wygląd Nertivii.."])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolory"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format Czasu"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawowy Motyw"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED Dark"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halloween 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstępnie Zdefiniowane Motywy"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niestandardowy CSS"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główny"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasek Nawigacyjny"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz nieukończone lub eksperymentalne funkcje."])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tej chwili nie są dostępne żadne funkcje WIP."])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącze wszystkie uprawnienia."])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłanie wiadomości"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozwala na wysyłanie wiadomości na kanałach serwera."])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządanie rolami"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozwala na tworzenie, edytowanie i usuwanie ról."])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie kanałami"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozwala na tworzenie, edytowanie i usuwanie ról."])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrzucanie członków"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozwala na wyrzucanie członków z serwera.."])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banowanie członków"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrzuca i niepozwala użytkownikowi dołączyć do serwera."])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogólne"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj Kanałami"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj Rolami"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj Zaproszeniami"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widzialność Serwera"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj Powiadomieniami"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbanowani Użytkownicy"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj użytkownikami"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń serwer"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj swoimi rolami."])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawowa Rola"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rola Bota"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stwóż Nową Rolę"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta rola nie może być usunięta/przydzielona innemu członkowi, ponieważ została stworzona przez bota. Usuń bota, aby usunąć tę rolę."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta rola nie może być usunięta lub nieprzypisana, ponieważ jest to rola domyślna, która jest stosowana dla każdego członka."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz Kolor Roli"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa Roli"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń Rolę"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwanie Roli"])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schowaj Rolę"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogólne Ustawienia Serwera"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj swoimi kanałami."])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń Kanał"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwanie Kanału..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa Kanału"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jest to kanał domyślny. Kanał domyślny można zmienić na stronie Ogólne. Kanałów domyślnych nie można usuwać."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stwóż Nowy Kanał"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłanie Wiadomości"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit wiadomości (sukund)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał tekstowy"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał HTML (WIP)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbanuj Stąd Użytkowników"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbanowywanie..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbanuj"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko administratorzy mogą widzieć zaproszenia wszystkich użytkowników."])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz Zaproszenia"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niestandardowe Zaproszenie"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz Łącze Niestandardowe"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zaproszenie"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącze"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twórca"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Używa"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj Użytkownika"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz nazwę serwera, aby potwierdzić"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwanie..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień ustawienia powiadomień."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te ustawienia będą dotyczyły tylko Ciebie i nie będą miały wpływu na innych."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz Powiadomienia"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wycisz Dźwięk"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłącz Powiadomienia"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " pisze..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " i ", _interpolate(_list(1)), " piszą..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " i ", _interpolate(_list(2)), " piszą..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " osób pisze..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajomi"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisane notatki"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj znajomego"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij, żeby dodać status własny"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko w porządku!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prywatne Wiadomości"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serwery"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkrywaj"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj znajomego poprzez wpisanie jego nazwy użytkownika i tagu."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodawanie..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj serwer"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz do serwera poprzez zaproszenie."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stwórz własny serwer. Zacznij od jego nazwania."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stwórz"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedostępny"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępny"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaraz wracam"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajęty"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcę grać"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Członkowie (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Niedostępni (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wspólni Znajomi"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odznaki"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołączył"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawieszone"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń Znajomego"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaakceptuj Prośbę"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj Prośbę"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj znajomego"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij Wiadomość"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wspólne Serwery"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwierzytelnianie..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizacja"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizacja Dostępna"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiowano!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wyszedł z serwera"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został wyrzucony"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został zbanowany"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dołączył do serwera!"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można połączyć się z serwerem."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb Powolny"])}
  }
}